<script setup lang="ts">
const localePath = useLocalePath()

const cta = reactive({
  img: '/production/uploads/photos/destination-387/2019-08-27-a1e5efc79b10d2e9d70e75e085a96f02.jpg',
  path: localePath('owners'),
})
</script>

<template>
  <section class="home-owner">
    <div class="home-owner__background" />

    <base-container>
      <base-row>
        <base-col cols="24" lg="9" offset-lg="3" class="home-owner__header">
          <h2 class="home-owner__title">
            {{ $t('home.owner.title') }}
          </h2>
          <p class="home-owner__description">
            {{ $t('home.owner.description') }}
          </p>

          <base-button :to="cta.path" color="secondary" class="mb-10">
            {{ $t('home.owner.cta') }}
          </base-button>
        </base-col>

        <base-col cols="24" lg="9" offset-lg="2">
          <BaseNuxtImg
            :alt="$t('home.owner.alt')"
            :src="cta.img"
            class="home-owner__img"
            fit="contain"
            height="600"
            width="452"
          />
        </base-col>
      </base-row>
    </base-container>
  </section>
</template>

<style>
.home-owner {
  @apply relative;
}

.home-owner__background {
  height: calc(100% - 2.875rem);
  @apply bg-gray-100 absolute w-full;
}

@screen md {
  .home-owner__background {
    height: calc(100% - 4.125rem);
  }
}

@screen lg {
  .home-owner__background {
    width: 70%;
    @apply left-0;
  }
}

.home-owner__header {
  @apply text-center;
}

@screen lg {
  .home-owner__header {
    @apply text-left;
  }
}

.home-owner__title {
  margin: 4rem 0 2rem 0;
}

@screen md {
  .home-owner__title {
    margin: 4rem 0 1.5rem 0;
    @apply text-8xl;
  }
}

@screen lg {
  .home-owner__title {
    margin: 7rem 0 2rem 0;
  }
}

.home-owner__description {
  margin: 0 0 2rem 0;
  @apply text-lg;
}

@screen lg {
  .home-owner__img {
    height: 600px;
    margin: 4rem 0 0;
  }
}
</style>
