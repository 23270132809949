<script setup lang="ts">
import type { HomeExperience } from '~/server/types/home'

const props = defineProps<{
  experience: HomeExperience
}>()

const localePath = useLocalePath()

const goToSearch = computed(() => {
  return localePath({
    name: 'search',
    query: { destinationId: props.experience.destiId },
  })
})
</script>

<template>
  <nuxt-link :to="goToSearch" class="home-experience-card relative">
    <BaseNuxtImg
      :alt="$t(`home.experiences.alt${experience.id}`)"
      :src="experience.img"
      fit="cover"
      height="594"
      loading="lazy"
      width="336"
    />

    <div class="home-experience-card__content">
      <p class="home-experience-card__text">
        {{ experience.destination }}
      </p>
      <h5 class="home-experience-card__title">
        {{ experience.title }}
      </h5>
      <p class="home-experience-card__text">
        {{ $t('home.experiences.get_to_desti') }}
        <BaseIcon class="home-experience-card__icon" name="arrowRight" />
      </p>
    </div>
  </nuxt-link>
</template>

<style>
.home-experience-card {
  height: 490px;
  @apply block;
}

@screen md {
  .home-experience-card {
    height: 594px;
  }
}

.home-experience-card__content {
  height: 76%;
  @apply text-white w-full absolute left-0 bottom-0 flex flex-col justify-end p-6;
  background: linear-gradient(
    180deg,
    rgba(32, 32, 32, 0),
    rgba(32, 32, 32, 0.75)
  );
  transition: all 0.5s;
}
@media (min-width: 992px) and (hover: hover) {
  .home-experience-card__content {
    @apply opacity-0;
  }

  .home-experience-card:hover .home-experience-card__content {
    @apply opacity-100;
  }
}

.home-experience-card__title {
  @apply text-white;
  margin: 0 0 1rem;
}

.home-experience-card__text {
  @apply text-base m-0;
}

.home-experience-card__icon {
  line-height: 1.5rem;
}
</style>
