<script setup lang="ts">
import type { HomeExperience } from '~/server/types/home'

defineProps<{
  experiences: HomeExperience[]
}>()

const swiperOptions = {
  preventClicksPropagation: true,
  breakpoints: {
    576: {
      centeredSlides: true,
      slidesPerView: 1.5,
      spaceBetween: 16,
    },
    768: {
      centeredSlides: false,
      slidesPerView: 2.02,
      spaceBetween: 24,
    },
  },
  centeredSlides: true,
  loop: true,
  slidesPerView: 1.2,
  spaceBetween: 16,
}

const masonryMargin = (index: number) => {
  let res = '0'
  const marginTop = [0, '10rem', '3.25rem', '6.125rem']

  if (marginTop[index]) {
    res = `${marginTop[index]} 0 0 0`
  }

  return res
}
const masonryAosDelay = (index: number) => 100 * index
</script>

<template>
  <div class="home-experiences">
    <base-container class="home-experiences__masonry-container">
      <base-row>
        <base-col
          v-for="(experience, index) in experiences"
          :key="experience.id"
        >
          <div
            :data-aos-delay="masonryAosDelay(index)"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <home-experience-card
              :experience="experience"
              :style="{ margin: masonryMargin(index) }"
            />
          </div>
        </base-col>
      </base-row>
    </base-container>

    <VirtualBaseCarousel
      class-name="home-experiences__carousel"
      :slides="experiences"
      :navigation="$device.isDesktop"
      :swiper-options="swiperOptions"
      :virtual="false"
    >
      <template #slider-content="{ slide, slideIndex }">
        <home-experience-card
          :key="slideIndex"
          :experience="slide as HomeExperience"
          class="home-experiences__carousel-item swiper-slide"
        />
      </template>
    </VirtualBaseCarousel>
  </div>
</template>

<style>
.home-experiences {
  @apply overflow-hidden;
  margin: 3rem 0 0 0;
}

@screen lg {
  .home-experiences {
    margin: 5.25rem 0 0 0;
  }
}

.home-experiences__masonry-container {
  @apply hidden;
}

@screen lg {
  .home-experiences__masonry-container {
    @apply block;
  }
}

@screen xl {
  .home-experiences__masonry-container {
    max-width: 1440px;
  }
}

.home-experiences__carousel.swiper {
  height: calc(490px + 2 * 0.25rem);
}

@screen md {
  .home-experiences__carousel.swiper {
    height: calc(594px + 2 * 0.25rem);
  }
}

@screen lg {
  .home-experiences__carousel.swiper {
    @apply hidden;
  }
}

@screen md {
  .home-experiences__carousel.swiper {
    padding: 0 2rem;
  }
}
</style>
